import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Miscellaneous things I've made:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/misc/video-controls-hider"
        }}>{`YouTube control hider Chrome extension`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/misc/autocrafting-plugin"
        }}>{`autocrafting and MOTD randomization plugin for Paper Minecraft`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      